import { ApexLegendsGameEvent, BlackMythWukongGameEvent, CallOfDutyModernWarfareGameEvent, CallOfDutyWarzoneGameEvent, CounterStrike2GameEvent, CsgoGameEvent, DarkAndDarkerGameEvent, Diablo4GameEvent, Dota2GameEvent, ErbsGameEvent, EscapeFromTarkovGameEvent, FortniteGameEvent, GameClassIdLike, GameEventType, Hades2GameEvent, HaloInfiniteGameEvent, HearthstoneGameEvent, Helldivers2GameEvent, HeroesOfTheStormGameEvent, InfoUpdateKeyAsEventName, LeagueTFTGameEvent, LeapGameEvent, LegendsOfRuneterraGameEvent, LethalCompanyGameEvent, LostArkGameEvent, MinecraftGameEvent, OverwatchGameEvent, PalworldGameEvent, PathOfExileGameEvent, PUBGGameEvent, RainbowSixSiegeGameEvent, RocketLeagueGameEvent, SonsOfTheForestGameEvent, SpectreDivideGameEvent, StarCraftIIGameEvent, TeamfightTacticsGameEvent, TheFinalsGameEvent, ValorantGameEvent, Warhammer40kSpaceMarine2GameEvent, WorldOfTanksGameEvent, WorldOfWarshipsGameEvent, WutheringWavesGameEvent, XdefiantGameEvent } from '@insights-gaming/game-events';
import { VideoPublicity, VideoUploadDestination } from '@insights-gaming/video-upload-slice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import flatten, { unflatten } from 'flat';
import { fromPairs, partition } from 'lodash';
import { typedPath, TypedPathHandlers, TypedPathKey, TypedPathWrapper } from 'typed-path';
import actionCreatorFactory from 'typescript-fsa';

import { CUSTOM_TAG_DEFAULT_COLORS, CustomTagDefaultColors } from '@/app/constants';
import { MixpanelEventSource } from '@/mixpanel/common';
import customUpdate from '@/utils/update';

import { CustomKeybinding, Keybinding, makeKeybindingIdentifier, negateKeybinding } from './keybinding';

const name = 'setting';
const actionCreator = actionCreatorFactory(name);
export const loadSettingDatabaseAC = actionCreator.async<void, Dictionary<{}>, Error>('LOAD_SETTING_DATABASE');
export const enableGameSettingForNewGamesAC = actionCreator.async<{ changeSource?: SettingChangedSource }, void, Error>('ENABLE_GAME_SETTING_FOR_NEW_GAMES');

export const additionalHandlers: Omit<SettingTypedPathHandlers, keyof DefaultHandlers> = {
  // number properties are joined as `[${number}]` when using $path.
  // https://github.com/bsalex/typed-path/blob/f483d886a43711fc88785edb3b1d56d83e382661/index.ts#L3
  // This forces the number to be joined using ".".
  $dotPath: (path: TypedPathKey[]) => path.join('.'),
};

// Just for getting overwolf hotkey as property in settingPath. Not stored as actual setting.
interface AdditionalSettingPaths {
  keybindings: unknown;
  owhotkeys: unknown;
}

export const settingPath = typedPath<Settings & AdditionalSettingPaths, SettingTypedPathHandlers>(additionalHandlers as SettingTypedPathHandlers);
// Reason for "[]" around TP is to avoid distributive conditional type when TP is a union type
// https://stackoverflow.com/a/70792483/15417072
export type SettingTypeFromPath<TP> = [TP] extends [TypedPathWrapper<infer T, infer _>] ? (
  T
) : [TP] extends [infer U & TypedPathHandlers<SettingTypedPathHandlers>] ? (
  U | undefined
) : never;

interface AppSettings {
  closeToTray: boolean;
  minimizeToTray: boolean;
  launchDesktopOnGameClose: boolean;
  showIngameWindow: boolean;
  minimizeDesktopWhenGameRunning: boolean;
  hideOverlayStatus: boolean;
  backups: {
    numberOfBackups: number;
  };
}

export type ManagementStyle = 'auto' | 'manual';

interface VideoLibrarySettings {
  videosPerPage: number;
  filesizeWarning: {
    enabled: boolean;
    threshold: number;
    managementStrategy: ManagementStyle;
    autoManagementOptions: {
      enableDeleteWarning: boolean;
    };
  };
  recycleBinAutomaticCleanup: {
    enabled: boolean;
    durationDays: number;
  };
}

export type VideoReplayRightPanelTab = 'notes' | 'comments' | 'clips' | 'stats';

interface VideoReplaySettings {
  autoplay: boolean;
  muted: boolean;
  pauseOnComment: boolean;
  pauseOnCommentClick: boolean;
  volume: number;
  rightPanelInitialTab: VideoReplayRightPanelTab | 'rememberLast' | 'collapsed';
  preseek: {
    enabled: boolean;
    amount: number;
  };
  toggleFullscreenControls: boolean;
  _lastNonZeroVolume: number;
  _lastRightPanel: {
    tab: VideoReplayRightPanelTab;
    collapsed: boolean;
  };
  showApmGraph: boolean;
}

export type StreamRecorderPresets = 'Performance' | 'Balanced' | 'Quality' | 'Custom';
export interface GameModeAutoRecording {
  enabled: boolean;
  custom?: boolean;
}

export type CaptureMode = 'auto' | 'session' | 'manual';

export type ProcessAudioCaptureSetting = {
  enable: boolean;
} & Omit<overwolf.streaming.ProcessAudioCapture, 'tracks'>;

interface StreamRecorderSettings {
  captureMode: CaptureMode;
  autoRecord: {
    delay: number;
    gameModes?: Dictionary<GameModeAutoRecording>;
    endDelay: number;
  };
  audio: {
    game: {
      enable: boolean;
      device_id?: string;
      volume: number;
      filtered_capture: {
        enable: boolean;
      };
    };
    mic: {
      enable: boolean;
      device_id?: string;
      volume: number;
      mono: boolean;
      inputMode: 'always' | 'pushToTalk';
      releaseDelay: number;
    };
    processCapture: {
      enable: boolean;
      track4: ProcessAudioCaptureSetting;
      track5: ProcessAudioCaptureSetting;
      track6: ProcessAudioCaptureSetting;
    };
  };
  encoder: {
    overallPreset?: StreamRecorderPresets;
    bitrate: number;
    framerate: number;
    verticalResolution: number;
    name?: overwolf.streaming.enums.StreamEncoder;
    config: {
      x264: {
        preset: overwolf.streaming.enums.StreamEncoderPreset_x264;
        rate_control: overwolf.streaming.enums.StreamEncoderRateControl_x264;
      };
      nvidia_nvenc: {
        preset: overwolf.streaming.enums.StreamEncoderPreset_NVIDIA;
        rate_control: overwolf.streaming.enums.StreamEncoderRateControl_NVIDIA;
      };
      amd_amf: {
        preset: overwolf.streaming.enums.StreamEncoderPreset_AMD_AMF;
        rate_control: overwolf.streaming.enums.StreamEncoderRateControl_AMD_AMF;
      };
      intel_quicksync: {
        preset: overwolf.streaming.enums.StreamEncoderPreset_Intel;
        rate_control: string;
      };
    };
  };
  instantReplay: {
    enable: boolean;
    pastDuration: number;
  };
  windowStreamingMode: overwolf.streaming.enums.StreamingMode;
  forceAspectRatio: {
    enabled: boolean;
    ratio: number;
  };
  forceGameWindowCapture: boolean;
}

interface ScreenRecorderSettings {
  countdown: {
    enabled: boolean;
    delay: number;
  };
  captureMouseCursor: overwolf.streaming.enums.StreamMouseCursor;
  monitor?: number;
  indicator: overwolf.streaming.enums.IndicationType;
  showWebcam?: boolean;
}

interface WebcamSettings {
  enabled: boolean;
  device_id?: string;
  transform: overwolf.media.replays.enums.eVideoSourceTransform;
  position: {
    x: number;
    y: number;
  };
  size_scale: {
    x: number;
    y: number;
  };
  captureMode: overwolf.streaming.enums.StreamingMode;
}

export interface MontageTiming {
  secsBeforeEvent: number;
  secsAfterEvent: number;
  minSecsBetweenEvent: number;
}

export interface MontageEventClipSetting {
  enabled: boolean;
  timings: MontageTiming;
}

export interface UserBookmarkMontageEvent {
  ICUserBookmark: MontageEventClipSetting;
}

export type PerGameMontageEvents<T extends GameClassIdLike = GameClassIdLike> = {
  [Key in GameEventType<T>['name'] | InfoUpdateKeyAsEventName<T>]?: MontageEventClipSetting;
};

export type MontageSettingsEvents<T extends GameClassIdLike = GameClassIdLike> = PerGameMontageEvents<T> & UserBookmarkMontageEvent;

interface MontageSettings<T extends GameClassIdLike> {
  autoMontage: boolean;
  deleteOriginal: boolean;
  events?: MontageSettingsEvents<T>;
}

export interface VideoUploadSettings {
  defaultDest?: VideoUploadDestination;
  defaultVideoPublicity: VideoPublicity;
  defaultClipPublicity: VideoPublicity;
  includeCommentsByDefault: boolean;
  includeGameEventsByDefault: boolean;
  autoUpload: {
    enable: boolean;
    destination?: VideoUploadDestination;
  };
  pauseUploadsInGame: boolean;
  nativeUploader: boolean;
  nativeUploaderLogging: boolean;
  uploadThreads: number;
}

export interface VideoSyncSettings {
  syncNewVideos: boolean;
}

export interface NotificationSettings {
  appLaunch: boolean;
  streamStarting: boolean;
  streamEnd: boolean;
  replayActive: boolean;
  replaySaved: boolean;
}

/**
 * @property {string} title - custom string the user sets and is displayed to the user
 * @property {string} color - custom color string the user sets
 */
export interface CustomHotkeyLabel {
  title?: string;
  color?: string;
}

/**
 * @property name - hotkey's name in manifest
 * @property activateName - hold hotkey in manifest used to activate hotkey
 */
export type MergedCustomHotkeyLabel = WithRequired<CustomHotkeyLabel, 'title'> & {
  name: CustomTagHotkeyName;
  activateName: CustomTagActivateHotkeyName;
};

/**
 * Data saved to indexedDB as a mapped type for easy access
 * @property [activateName] - name of hold hotkey used as the modifier to "activate" tagging hotkey
 * @property [name] - hotkey name used as keys to access the CustomHotkeyLabel. Should be same as CustomHotkeyLabel['name'].
 *
 * @example
 * ```ts
 * {
 *   [activateName]: {
 *     [name]: { ...CustomHotkeyLabel }
 *   }
 * }
 * ```
 */
export type CustomHotkeyLabelMap<T extends CustomHotkeyLabel = CustomHotkeyLabel> = {
  [activateName in CustomTagActivateHotkeyName]: {
    [name in CustomTagHotkeyName]?: T;
  }
};

interface CustomTagKeybindingsSettings {
  enabled: boolean;
  labels: CustomHotkeyLabelMap;
}

export type InputOverlayLayout = 'qwerty' | 'qwertz' | 'azerty';
export type InputOverlaySize = 'sm' | 'md' | 'lg';
export type InputOverlayPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export interface InputOverlaySettings {
  enabled: boolean;
  hideInGame: boolean;
  opacity: number;
  size: InputOverlaySize;
  position: InputOverlayPosition;
  layout: InputOverlayLayout;
}

export interface InGameSecondaryWindowSectionSettings {
  hotkeys: boolean;
  recordingInfo: boolean;
  storageInfo: boolean;
  openReplay: boolean;
  microphone: boolean;
}

export interface AppearanceSettings {
  inGameSecondaryWindow: {
    enabled: boolean;
    sections: InGameSecondaryWindowSectionSettings;
  };
  customTheme: {
    palette: {
      colorTheme: string;
      primary?: string;
      secondary?: string;
    };
    lightMode: boolean;
    backgroundImage: string;
    backgroundImageOpacity: number;
  };
}

/**
 * @property selected - enable/disable overlay for each game.
 *   Enabled: Show overlay and show check mark in settings.
 *   Disabled: Clicked by user to disable overlay.
 *   Undefined/not set: Change to enabled if playing games for first time. Do not show as selected in settings.
 */
export interface ICDisabledGameSettings {
  selected: Partial<Record<GameClassIdLike | number, 'enabled' | 'disabled'>>;
}

export interface Settings<T extends GameClassIdLike = GameClassIdLike> {
  app: AppSettings;
  montage: MontageSettings<T>;
  videoLibrary: VideoLibrarySettings;
  videoReplay: VideoReplaySettings;
  videoUpload: VideoUploadSettings;
  videoSync: VideoSyncSettings;
  streamRecorder: StreamRecorderSettings;
  screenRecorder: ScreenRecorderSettings;
  webcam: WebcamSettings;
  notification: NotificationSettings;
  customKeybindings: Keybinding[];
  customTagKeybindings: CustomTagKeybindingsSettings;
  inputOverlay: InputOverlaySettings;
  language: {
    language?: string;
  };
  appearance: AppearanceSettings;
  icDisabledGame: ICDisabledGameSettings;
}

type PerGameSettings = { [K in GameClassIdLike]?: DeepPartial<Settings<K>> };
interface InternalSettingState {
  settingDatabaseLoaded: boolean;
}

export type SettingsState = Settings & PerGameSettings & InternalSettingState;

interface SettingChangedPayload {
  setting: DeepPartial<SettingsState>;
}

export type SettingChangedSource = MixpanelEventSource<'Settings Page' | 'Secondary Window' | 'Video Management Drawer' | 'Onboarding' | 'Capture Mode Notification' | 'Video Replay Page'>;

interface SettingChangedMeta {
  changeSource?: SettingChangedSource;
}

interface SettingResetPayload {
  settingPath: string | string[];
}

interface SettingResetMeta {
  resetSource?: SettingChangedSource;
}

function tryGetOverwolfValue<T>(accessor: (ow: typeof overwolf) => T, fallback: string): T {
  if (typeof overwolf === 'undefined') {
    return fallback as any;
  }
  return accessor(overwolf);
}

function makeHotkeyLabelDefaults(defaultColors: CustomTagDefaultColors): CustomHotkeyLabelMap {
  return unflatten<Dictionary<{ color: string }>, CustomHotkeyLabelMap>(Object.fromEntries(
    Object.entries(flatten<CustomTagDefaultColors, Dictionary<string>>(defaultColors))
      .map(([k, v]) => [k, { color: v }]),
  ));
}

export const defaultMontageTimings = {
  secsBeforeEvent: 3,
  secsAfterEvent: 2,
  minSecsBetweenEvent: 10,
};

const mobaDefaultMontageTimings = {
  secsBeforeEvent: 9,
  secsAfterEvent: 3,
  minSecsBetweenEvent: 20,
};

/**
 * Function to reduce all the lines needed to fit timings into each event.
 * @param events game events to show and their default enabled state
 * @param timings default timings for all events
 */
function makeMontageEventsDefaults<T extends GameClassIdLike = GameClassIdLike>(
  events: Partial<Record<keyof PerGameMontageEvents<T>, boolean>>,
  timings: MontageTiming,
): MontageSettings<T>['events'] {
  const userBookmarkMontageEvent: UserBookmarkMontageEvent = {
    ICUserBookmark: {
      enabled: false,
      timings,
    },
  };
  const gameMontageEvent = new Map<keyof PerGameMontageEvents<T>, MontageEventClipSetting>();

  for (const eventName in events) {
    const enabled = events[eventName as keyof PerGameMontageEvents<T>];
    if (enabled !== undefined) {
      gameMontageEvent.set(
        eventName,
        {
          enabled,
          timings,
        },
      );
    }
  }
  return Object.assign(userBookmarkMontageEvent, Object.fromEntries(gameMontageEvent));
}

export const defaultSettings: SettingsState = {
  settingDatabaseLoaded: false,
  app: {
    closeToTray: true,
    minimizeToTray: false,
    launchDesktopOnGameClose: true,
    showIngameWindow: true,
    minimizeDesktopWhenGameRunning: false,
    hideOverlayStatus: false,
    backups: {
      numberOfBackups: 10,
    },
  },
  videoLibrary: {
    videosPerPage: 24,
    filesizeWarning: {
      enabled: true,
      threshold: 25,
      managementStrategy: 'manual',
      autoManagementOptions: {
        enableDeleteWarning: true,
      },
    },
    recycleBinAutomaticCleanup: {
      enabled: true,
      durationDays: 5,
    },
  },
  videoReplay: {
    autoplay: true,
    muted: false,
    pauseOnComment: false,
    pauseOnCommentClick: true,
    volume: 1,
    rightPanelInitialTab: 'rememberLast',
    preseek: {
      enabled: false,
      amount: 10,
    },
    toggleFullscreenControls: false,
    _lastNonZeroVolume: 1,
    _lastRightPanel: {
      tab: 'comments',
      collapsed: true,
    },
    showApmGraph: true,
  },
  videoUpload: {
    defaultVideoPublicity: 'PRIVATE',
    defaultClipPublicity: 'OPEN_COMMENTS',
    includeCommentsByDefault: true,
    includeGameEventsByDefault: true,
    autoUpload: {
      enable: false,
    },
    pauseUploadsInGame: true,
    nativeUploader: true,
    nativeUploaderLogging: false,
    uploadThreads: 1,
  },
  videoSync: {
    syncNewVideos: false,
  },
  streamRecorder: {
    captureMode: 'auto',
    autoRecord: {
      delay: 5,
      endDelay: 10,
    },
    audio: {
      game: {
        enable: true,
        volume: 100,
        filtered_capture: {
          enable: false,
        },
      },
      mic: {
        enable: true,
        volume: 100,
        mono: false,
        inputMode: 'always',
        releaseDelay: 20,
      },
      processCapture: {
        enable: false,
        track4: {
          enable: false,
          process_name: '',
          volume: 100,
        },
        track5: {
          enable: false,
          process_name: '',
          volume: 100,
        },
        track6: {
          enable: false,
          process_name: '',
          volume: 100,
        },
      },
    },
    encoder: {
      bitrate: 4000,
      framerate: 30,
      verticalResolution: 720,
      // name: tryGetOverwolfValue<overwolf.streaming.enums.StreamEncoder>(overwolf => overwolf.streaming.enums.StreamEncoder.X264, 'X264' as any),
      config: {
        x264: {
          preset: tryGetOverwolfValue<overwolf.streaming.enums.StreamEncoderPreset_x264>(overwolf => overwolf.streaming.enums.StreamEncoderPreset_x264.ULTRAFAST, 'ULTRAFAST'),
          rate_control: tryGetOverwolfValue<overwolf.streaming.enums.StreamEncoderRateControl_x264>(overwolf => overwolf.streaming.enums.StreamEncoderRateControl_x264.RC_CBR, 'RC_CBR'),
        },
        nvidia_nvenc: {
          preset: tryGetOverwolfValue<overwolf.streaming.enums.StreamEncoderPreset_NVIDIA>(overwolf => overwolf.streaming.enums.StreamEncoderPreset_NVIDIA.HIGH_PERFORMANCE, 'HIGH_PERFORMANCE'),
          rate_control: tryGetOverwolfValue<overwolf.streaming.enums.StreamEncoderRateControl_NVIDIA>(overwolf => overwolf.streaming.enums.StreamEncoderRateControl_NVIDIA.RC_CBR, 'RC_CBR'),
        },
        amd_amf: {
          preset: tryGetOverwolfValue<overwolf.streaming.enums.StreamEncoderPreset_AMD_AMF>(overwolf => overwolf.streaming.enums.StreamEncoderPreset_AMD_AMF.SPEED, 'SPEED'),
          rate_control: tryGetOverwolfValue<overwolf.streaming.enums.StreamEncoderRateControl_AMD_AMF>(overwolf => overwolf.streaming.enums.StreamEncoderRateControl_AMD_AMF.RC_CBR, 'RC_CBR'),
        },
        intel_quicksync: {
          // overwolf.streaming.enums.StreamEncoverPresets_Intel doesn't exist despite being in the definition file.
          // Use string below instead of `tryGetOverwolfValue` with Intel enum
          preset: tryGetOverwolfValue<overwolf.streaming.enums.StreamEncoderPreset_Intel>(overwolf => overwolf.streaming.enums.StreamEncoderPreset_Intel.QUALITY, 'QUALITY'),
          rate_control: tryGetOverwolfValue<overwolf.streaming.enums.StreamEncoderRateControl_Intel>(overwolf => overwolf.streaming.enums.StreamEncoderRateControl_Intel.RC_CBR, 'RC_CBR'),
        },
      },
    },
    windowStreamingMode: tryGetOverwolfValue<overwolf.streaming.enums.StreamingMode>(overwolf => overwolf.streaming.enums.StreamingMode.Never, 'Never'),
    instantReplay: {
      enable: false,
      pastDuration: 60,
    },
    forceGameWindowCapture: true,
    forceAspectRatio: {
      enabled: false,
      ratio: 16 / 9,
    },
  },
  webcam: {
    enabled: false,
    size_scale: {
      x: 0.25,
      y: 0.25,
    },
    position: {
      x: 0,
      y: 0,
    },
    transform: tryGetOverwolfValue<overwolf.media.replays.enums.eVideoSourceTransform>(overwolf => overwolf.media.replays.enums.eVideoSourceTransform.DockBottomLeft, 'DockBottomLeft'),
    captureMode: tryGetOverwolfValue<overwolf.streaming.enums.StreamingMode>(overwolf => overwolf.streaming.enums.StreamingMode.Always, 'Always'),
  },
  screenRecorder: {
    countdown: {
      enabled: true,
      delay: 3,
    },
    captureMouseCursor: tryGetOverwolfValue<overwolf.streaming.enums.StreamMouseCursor>(overwolf => overwolf.streaming.enums.StreamMouseCursor.both, 'both'),
    indicator: tryGetOverwolfValue<overwolf.streaming.enums.IndicationType>(overwolf => overwolf.streaming.enums.IndicationType.DotAndTimer, 'DotAndTimer'),
    showWebcam: false,
  },
  montage: {
    autoMontage: true,
    deleteOriginal: false,
    events: {
      ICUserBookmark: {
        enabled: false,
        timings: defaultMontageTimings,
      },
    },
  },
  notification: {
    appLaunch: true,
    streamStarting: true,
    streamEnd: true,
    replayActive: true,
    replaySaved: true,
  },
  customKeybindings: [],
  customTagKeybindings: {
    enabled: true,
    labels: makeHotkeyLabelDefaults(CUSTOM_TAG_DEFAULT_COLORS),
  },
  inputOverlay: {
    enabled: false,
    hideInGame: false,
    opacity: 0.75,
    size: 'md',
    position: 'bottom-left',
    layout: 'qwerty',
  },
  language: {

  },
  appearance: {
    inGameSecondaryWindow: {
      enabled: true,
      sections: {
        hotkeys: true,
        recordingInfo: true,
        storageInfo: true,
        openReplay: true,
        microphone: true,
      },
    },
    customTheme: {
      palette: {
        colorTheme: 'dark',
        primary: '',
        secondary: '',
      },
      lightMode: false,
      backgroundImage: '',
      backgroundImageOpacity: 1,
    },
  },
  icDisabledGame: {
    selected: {},
  },
  [ApexLegendsGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          kill: true,
          knockdown: false,
          knocked_out: false,
        },
        defaultMontageTimings,
      ),
    },
  },
  [BlackMythWukongGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          kill: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [CallOfDutyModernWarfareGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          kill: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [CallOfDutyWarzoneGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          kill: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [CounterStrike2GameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          kill: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [CsgoGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          headshot: false,
          kill: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [DarkAndDarkerGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
        },
        defaultMontageTimings,
      ),
    },
  },
  [Diablo4GameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({}, defaultMontageTimings),
    },
  },
  [Dota2GameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          kill: true,
        },
        mobaDefaultMontageTimings,
      ),
    },
  },
  [ErbsGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults<typeof ErbsGameEvent.CLASS_ID>(
        {
          downed: false,
          knock_down: true,
        },
        mobaDefaultMontageTimings,
      ),
    },
  },
  [EscapeFromTarkovGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
        },
        defaultMontageTimings,
      ),
    },
  },
  [FortniteGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          kill: true,
          knockout: false,
          knockedout: false,
        },
        defaultMontageTimings,
      ),
    },
  },
  [Hades2GameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [HaloInfiniteGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          kill: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [HearthstoneGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({}, defaultMontageTimings),
    },
  },
  [Helldivers2GameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
        },
        defaultMontageTimings,
      ),
    },
  },
  [HeroesOfTheStormGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          kill: true,
        },
        mobaDefaultMontageTimings,
      ),
    },
  },
  [LeagueTFTGameEvent.CLASS_ID]: {
    // League
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          kill: true,
        },
        mobaDefaultMontageTimings,
      ),
    },
  },
  [LeapGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          kill: true,
        },
        mobaDefaultMontageTimings,
      ),
    },
  },
  [LegendsOfRuneterraGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({}, defaultMontageTimings),
    },
  },
  [LethalCompanyGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          respawn: false,
        },
        defaultMontageTimings,
      ),
    },
  },
  [LostArkGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({}, defaultMontageTimings),
    },
  },
  [MinecraftGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({}, defaultMontageTimings),
    },
  },
  [OverwatchGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          elimination: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [PalworldGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          knock_out: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [PathOfExileGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          boss_kill: true,
          death: false,
        },
        defaultMontageTimings,
      ),
    },
  },
  [PUBGGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          headshot: false,
          kill: true,
          knockout: false,
          knockedout: false,
        },
        defaultMontageTimings,
      ),
    },
  },
  [RainbowSixSiegeGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          headshot: false,
          kill: true,
          knockedout: false,
        },
        defaultMontageTimings,
      ),
    },
    streamRecorder: {
      autoRecord: {
        gameModes: {
          MATCHMAKING_PVP: {
            enabled: true,
          },
          MATCHMAKING_PVP_EVENT: {
            enabled: true,
          },
          MATCHMAKING_PVP_UNRANKED: {
            enabled: true,
          },
          MATCHMAKING_PVP_RANKED: {
            enabled: true,
          },
          CUSTOMGAME_PVP: {
            enabled: true,
          },
          CUSTOMGAME_PVP_DEDICATED: {
            enabled: true,
          },
        },
      },
    },
  },
  [RocketLeagueGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          opposingTeamGoal: false,
          teamGoal: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [SonsOfTheForestGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
        },
        defaultMontageTimings,
      ),
    },
  },
  [SpectreDivideGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          kill: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [StarCraftIIGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({}, defaultMontageTimings),
    },
  },
  [TeamfightTacticsGameEvent.PSEUDO_CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({}, defaultMontageTimings),
    },
  },
  [TheFinalsGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          elimination: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [ValorantGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          assist: false,
          death: false,
          headshot: false,
          kill: true,
        },
        defaultMontageTimings,
      ),
    },
    streamRecorder: {
      autoRecord: {
        gameModes: {
          bomb: {
            enabled: true,
            custom: true,
          },
          rated: {
            enabled: true,
          },
          quick_bomb: {
            enabled: true,
            custom: true,
          },
          team_deathmatch: {
            enabled: true,
            custom: true,
          },
          deathmatch: {
            enabled: true,
            custom: true,
          },
          escalation: {
            enabled: true,
            custom: true,
          },
          replication: {
            enabled: true,
            custom: true,
          },
          swift: {
            enabled: true,
            custom: true,
          },
          range: {
            enabled: true,
          },
        },
      },
    },
  },
  [Warhammer40kSpaceMarine2GameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults(
        {
          death: false,
          knockout: true,
        },
        defaultMontageTimings,
      ),
    },
  },
  [WorldOfTanksGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({
        assist: false,
        death: false,
        kill: true,
      },
      defaultMontageTimings),
    },
  },
  [WorldOfWarshipsGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({
        kill: true,
        death: false,
      },
      defaultMontageTimings),
    },
  },
  [WutheringWavesGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({
        death: false,
        respawn: false,
      },
      defaultMontageTimings),
    },
  },
  [XdefiantGameEvent.CLASS_ID]: {
    montage: {
      events: makeMontageEventsDefaults({
        death: false,
        elimination: true,
      },
      defaultMontageTimings),
    },
  },
};

const initialState: SettingsState = {
  settingDatabaseLoaded: false,
  customKeybindings: [],
  customTagKeybindings: {
    labels: {},
  },
} as any;

const settingSlice = createSlice({
  name,
  initialState,
  reducers: {
    settingChanged: {
      reducer(state, action: PayloadAction<SettingChangedPayload>) {
        return customUpdate(state, { $deepmerge: action.payload.setting });
      },
      prepare(payload: SettingChangedPayload, meta?: SettingChangedMeta) {
        return { payload, meta };
      },
    },
    settingReset: {
      reducer(state, action: PayloadAction<SettingResetPayload>) {
        const { settingPath } = action.payload;
        const paths = Array.isArray(settingPath) ? settingPath : [settingPath];
        return customUpdate(state, { $deepunset: paths });
      },
      prepare(payload: SettingResetPayload, meta?: SettingResetMeta) {
        return { payload, meta };
      },
    },
    updateKeybinding(state, action: PayloadAction<CustomKeybinding[]>) {
      const map = new Map(
        state.customKeybindings.map(k => ([makeKeybindingIdentifier(k), k])),
      );
      for (const keybinding of action.payload) {
        map.set(makeKeybindingIdentifier(keybinding), keybinding);
      }
      state.customKeybindings = Array.from(map.values());
    },
    unbindKeybinding(state, action: PayloadAction<CustomKeybinding[]>) {
      const map = new Map(
        state.customKeybindings.map(k => ([makeKeybindingIdentifier(k), k])),
      );
      const arr: Keybinding[] = [];
      for (const keybinding of action.payload) {
        map.delete(makeKeybindingIdentifier(keybinding));
        arr.push(negateKeybinding(keybinding));
      }
      state.customKeybindings = Array.from(map.values()).concat(arr);
    },
    resetKeybinding(state, action: PayloadAction<Keybinding[]>) {
      const map = new Map(
        state.customKeybindings.map(k => ([makeKeybindingIdentifier(k), k])),
      );
      for (const keybinding of action.payload) {
        map.delete(makeKeybindingIdentifier(keybinding));
      }
      state.customKeybindings = Array.from(map.values());
    },
  },
  extraReducers: builder => {
    builder.addCase(loadSettingDatabaseAC.done, (state, action) => {
      const [arraySettings, nonArraySettings] = partition(
        Object.entries(action.payload.result),
        ([_key, value]) => Array.isArray(value),
      );
      // Need to unflatten separately for settings stored as array and setting that has gameClassId as key
      const unflattened: SettingsState = {
        ...unflatten(fromPairs(arraySettings)),
        ...unflatten(fromPairs(nonArraySettings), { object: true }),
      };
      unflattened.settingDatabaseLoaded = true;
      return customUpdate(state, { $deepmerge: unflattened });
    });
  },
});

export const { settingChanged, settingReset, updateKeybinding, unbindKeybinding, resetKeybinding } = settingSlice.actions;
export const settingReducer = settingSlice.reducer;
